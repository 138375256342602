
import { defineComponent, ref, onMounted, computed } from "vue"
import ApiService from "@/core/services/ApiService"
import { RoleUser } from "@/core/models/User"
import { WidgetUser } from "@/core/models/WidgetUser"
import store from "@/store"
import { AccountRole } from "@/store/modules/AuthModule"
import { Mutations } from "@/store/enums/StoreEnums"

export default defineComponent({
  name: "widget-user",
  components: {},
  setup() {
    const selectedAccountUserId = ref(null)
    const widgetListIsLoading = ref(false)
    const roleUserList = ref<RoleUser[]>([])
    const widgetUserList = ref<WidgetUser[]>([])

    const getWidgetUserList = async () => {
      const response = await ApiService.get("widget/user/" + selectedAccountUserId.value)
      widgetUserList.value = response.data
      return response.data
    }

    const activeAccount = computed(() => store.getters.activeAccount as AccountRole)

    async function getUserList() {
      const { data } = await ApiService.get("user")
      roleUserList.value = data
    }

    const changeWidgetPermission = async (widgetUser: WidgetUser) => {
      const params = {
        ...widgetUser,
        accountUserId: selectedAccountUserId.value,
      }
      await ApiService.post("widget/user", params)
      const list = await getWidgetUserList()

      if (selectedAccountUserId.value == activeAccount.value.accountUserId)
        store.commit(Mutations.SET_WIDGET_LIST, list)
    }

    async function userWidgetChange(widgetUser: WidgetUser) {
      changeWidgetPermission(widgetUser)
    }

    onMounted(async () => {
      await getUserList()
    })

    return {
      widgetListIsLoading,
      selectedAccountUserId,
      roleUserList,
      widgetUserList,
      getWidgetUserList,
      userWidgetChange,
    }
  },
})
